import '../css/bootstrap.scss'

import { Tooltip, Popover } from 'bootstrap'
import bsCustomFileInput from 'bs-custom-file-input'
import { DOMReady } from './lib/domready'

document
  .querySelectorAll('[data-bs-toggle="tooltip"]')
  .forEach((element) => new Tooltip(element))
document
  .querySelectorAll('[data-bs-toggle="popover-dynamic"]')
  .forEach((element) => new Popover(element))

DOMReady(function () {
  bsCustomFileInput.init('.form-control[type="file"]')

  document
    .querySelectorAll('[data-bs-toggle="popover-dynamic"]')
    .forEach((popover) => {
      popover.addEventListener('inserted.bs.popover', (eArg) => {
        let callback = popover.dataset.bsCallback
        if (callback !== void 0) {
          try {
            window[callback].call(null, eArg)
          } catch (e) {}
        }
      })
    })
})
